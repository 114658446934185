var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"summaryStudentInfoTemplate",staticClass:"page"},[_c('global-page-back',{attrs:{"detailPageTitle":"档案模板"},on:{"handlerGlobalType":_vm.back}}),_c('div',{ref:"container",staticClass:"container"},[_c('div',{staticClass:"template-list-div"},[_c('div',{staticClass:"template-add-btn-div"},[_c('div',{staticClass:"template-add-btn",on:{"click":_vm.addTemplateDialogShow}},[_c('img',{attrs:{"src":_vm.plusIcon,"alt":""}}),_c('span',[_vm._v("添加档案")])])]),_c('div',{staticClass:"template-list"},[_c('draggable',{attrs:{"list":_vm.templateList,"animation":340,"group":"componentsGroup"},on:{"change":_vm.dragChange}},_vm._l((_vm.templateList),function(item,index){return _c('div',{key:index,staticClass:"template-item",class:index == _vm.selectedTemplate
                                ? 'template-item-selected'
                                : '',on:{"click":function($event){return _vm.templateClick(item, index)}}},[_c('span',{staticClass:"template-name"},[_vm._v(_vm._s(item.archivesName))]),_c('img',{staticClass:"template-item-edit",attrs:{"src":index == _vm.selectedTemplate
                                    ? _vm.editBlueIcon
                                    : _vm.editIcon,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.editTemplate(item, index)}}}),_c('img',{staticClass:"template-item-del",attrs:{"src":index == _vm.selectedTemplate
                                    ? _vm.delRedIcon
                                    : _vm.delIcon,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.delTemplate(item, index)}}})])}),0)],1)]),_c('div',{staticClass:"main"},[_c('DesignTemplate',{key:_vm.templateList[_vm.selectedTemplate]
                        ? _vm.templateList[_vm.selectedTemplate].id
                        : '',attrs:{"template-base-info":_vm.templateList[_vm.selectedTemplate]
                        ? _vm.templateList[_vm.selectedTemplate]
                        : null}})],1)]),(_vm.templateDialogObj.dialogVisible)?_c('DialogWrapper',{attrs:{"dialogObj":_vm.templateDialogObj},on:{"handleClose":function($event){_vm.templateDialogObj.dialogVisible = false}}},[_c('el-form',{ref:"templateForm",staticClass:"common-form",attrs:{"model":_vm.templateForm,"rules":_vm.templateRules,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"模板名称","prop":"archivesName"}},[_c('el-input',{staticClass:"input-width-medium",attrs:{"placeholder":"请输入模板名称"},model:{value:(_vm.templateForm.archivesName),callback:function ($$v) {_vm.$set(_vm.templateForm, "archivesName", $$v)},expression:"templateForm.archivesName"}})],1),_c('el-form-item',{attrs:{"label":"档案内容更新方式","prop":"updateType"}},[_c('el-select',{staticClass:"input-width-medium",attrs:{"placeholder":"请选择档案内容更新方式","disabled":_vm.updateTypeDisabled},model:{value:(_vm.templateForm.updateType),callback:function ($$v) {_vm.$set(_vm.templateForm, "updateType", $$v)},expression:"templateForm.updateType"}},_vm._l((_vm.updateTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"explainText"},[_vm._v(" 累加型：档案中的内容 ，以叠加方式逐年增加 ")]),_c('div',{staticClass:"explainText"},[_vm._v(" 更新型：档案内容只能修改不能叠加（可用于户口信息更新） ")])],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"align":"right"}},[_c('xk-button',{attrs:{"type":"info"},on:{"click":function($event){_vm.templateDialogObj.dialogVisible = false}}},[_vm._v("取 消")]),_c('xk-button',{attrs:{"type":"primary"},on:{"click":_vm.templateSave}},[_vm._v("确 定")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }